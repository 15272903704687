import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const PressKitBps = css(
  mq({
    marginTop: [34.5, 35, 32, 52]
  })
)

export const H4Bps = css(
  mq({
    fontSize: [33.6],
    color: ['rgba(0, 0, 0, 0.87)'],
    fontWeight: [500],
    maxWidth: [328, '100%', 680, 680]
  })
)

export const marginHeading = css(
  mq({
    marginLeft: [0, 0, 200, 302]
  })
)

export const PBps = css(
  mq({
    fontSize: [14],
    maxWidth: [328, '100%', 680, 680],
    color: ['rgba(0, 0, 0, 0.6)']
  })
)

export const styledOurJourney = css`
  ${PressKitBps};
`

export const headignContent = css`
  display: flex;
  h4 {
    ${H4Bps};
    ${marginHeading};
    letter-spacing: 0.25px;
  }
  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    ${marginHeading};
  }
  p {
    ${marginHeading};
    ${PBps};
    line-height: 1.71;
    margin: 0 auto;
    margin-top: 8px;
  }
`
export const ourJorney = css`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`

export const ourJorneyItem = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h5 {
    width: 55px;
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  span {
    width: 328px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 8px;
  }
`
export const circle = css`
  background: #ff5800;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  img {
    width: 36px;
  }
`

export const circleAfter = css`
  background: rgb(255, 245, 239);
    width: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 110px;
    align-items: center;
`

export const dots = css`
  margin-top: 16px;
  margin-bottom: 16px;
  div {
    height: 6px;
    width: 6px;
    background-color: #d9dee3;
    border-radius: 50%;
    margin-bottom: 10px;
  }
`
export const dots2 = css`
  margin-top: 16px;
  margin-bottom: 8px;
  height: 12px;
  width: 12px;
  background: #ff5800;
  border-radius: 50%;
`