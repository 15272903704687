import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import Img1 from '../images/our-journey/1.svg'
import Img2 from '../images/our-journey/2.svg'
import Img3 from '../images/our-journey/3.svg'
import Img4 from '../images/our-journey/4.svg'
import Img5 from '../images/our-journey/5.svg'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import { 
  styledOurJourney,
  headignContent,
  ourJorney,
  ourJorneyItem,
  circleAfter,
  dots,
  dots2
} from '../components/styles/OurJourney.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledOurJourney}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={headignContent}
        >
          <Column
            width={[1]}
            mb={[60, 60, 60, 60]}
          >
            <span>IMPRENSA</span>
            <H4>Nossa Jornada</H4>
            <Paragraph>A excelência em organização e atendimento é o que nos leva ao crescimento. Para isso, estamos constantemente buscando nos aprimorar e tornar a nossa operação ainda mais eficiente, todos os dias. Conheça o que nos trouxe até aqui.</Paragraph>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'row']}
          pb={70}
        >
          <Column
            width={[1]}
          >
            <div css={ourJorney}>
              <div css={ourJorneyItem}>
                <img src={Img1} />
                <H5>2003</H5>
                <span>Abrimos nosso primeiro data center em Orlando, Flórida.</span>
                <div css={dots}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div css={ourJorneyItem}>
                <div css={circleAfter} style={{ background: '#fff5ef', width: 110, borderRadius: '50%' }}>
                  <img src={Img2} />
                </div>
                <H5>2006</H5>
                <span>Inauguramos a primeira filial da HostDime fora dos EUA, no Brasil.</span>
                <div css={dots}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div css={ourJorneyItem}>
                <img src={Img3} />
                <H5>2008</H5>
                <span>Lançamos a HostDime México.</span>
                <div css={dots2} />
                <span>Lançamos a HostDime Colômbia.</span>
                <div css={dots2} />
                <span>Lançamos a HostDime UK.</span>
                <div css={dots}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div css={ourJorneyItem}>
                <img src={Img3} />
                <H5>2010</H5>
                <span>Lançamos a HostDime India.</span>
                <div css={dots}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div css={ourJorneyItem}>
                <img src={Img4} />
                <H5>2012</H5>
                <span>Holanda e Hong Kong são <br />adicionadas à nossa rede.</span>
                <div css={dots}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div css={ourJorneyItem}>
                <img src={Img5} />
                <H5>2017</H5>
                <span style={{ marginBottom: 24 }}>Inauguramos a infraestrutura de data center mais moderna do Norte/Nordeste brasileiro, em João Pessoa.</span>
              </div>
              <Button
                onClick={() => navigate('/data-center-nordeste')}
                outlined
                schema='lightPeriwinkle'
                align='center'
                mb={[3]}
                style={{
                  color: '#737474',
                  fontWeight: 600,
                  letterSpacing: 1.25,
                  lineHeight: 1.16,
                  padding: '14px 16px',
                  width: 140,
                  border: 'solid 1px rgba(0, 0, 0, 0.12)',
                  outline: 'none',
                  display: 'flex',
                  margin: '0 auto',
                  justifyContent: 'center'
                }}
              >
                CONHEÇA
              </Button>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Nossa Jornada' />
      <PageTemplate
        title='Nossa Jornada'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
